import { v4 as uuid } from "uuid";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import AudioInstance from "../../api/AudioApi";
import VisualMediaInstance from "../../api/VisualMediaApi";
import {
  IAudio,
  IOverlay,
  IVisualMedia,
  VisualMediaClassificationEnum,
} from "../../types/models";
import {
  IPendingUploadItem,
  IScheduledShow,
  IStreamPlaylistType,
  IStreamSelectedItem,
} from "../../types/interfaces";
import { axiosInstance } from "../../helpers/request";
import axios from "axios";
import { RootState } from "../store";
import { Uploader } from "../../helpers/Uploader";
import { notification } from "antd";
import { getLastOrder, parseShowItemId } from "../../helpers/helpers";
import {
  JINGLE_ITEM_PREFIX,
  SHOW_ITEM_PREFIX,
} from "../../constants/constants";
import authServiceInstance from "../../api/AuthApi";

interface MediaState {
  importItems: any[];
  loading: boolean;
  getItemsLoading: boolean;
  uploadedItem: IAudio | IVisualMedia | null;
  msg: string;
  error: string;
  items: IAudio[] | IVisualMedia[];
  hasMore: boolean;
  searchResult: IAudio[] | IVisualMedia[];
  isSearching: boolean;
  UploadItemLoading: boolean;
  currentItemUploadingId: string | null;
  uploadItemProgress: number;
  pendingUploads: IPendingUploadItem[];
  selectedItems: IStreamSelectedItem[];
  selectedJingleItems: IStreamSelectedItem[];
  autoAddToSelected: boolean;
  checkboxItemsIds: string[];
  backgrounds: IVisualMedia[];
  getBackgroundsLoading: boolean;
  selectedBackgroundId: string | null;
  uploadBackgroundProgress: number;
  overlaysLoading: boolean;
  scheduledShows: IScheduledShow[];
  visiblePlaylistType: IStreamPlaylistType;
  backgroundSize: number;
  playlistVideosSize: number;
  audioSize: number;
}

const initialState: MediaState = {
  importItems: [],
  loading: false,
  getItemsLoading: false,
  uploadedItem: null,
  msg: "",
  error: "",
  items: [],
  hasMore: false,
  searchResult: [],
  isSearching: false,
  UploadItemLoading: false,
  currentItemUploadingId: null,
  uploadItemProgress: 0,
  pendingUploads: [],
  selectedItems: [],
  selectedJingleItems: [],
  autoAddToSelected: false,
  checkboxItemsIds: [],
  backgrounds: [],
  getBackgroundsLoading: false,
  selectedBackgroundId: null,
  uploadBackgroundProgress: 0,
  overlaysLoading: false,
  scheduledShows: [],
  visiblePlaylistType: IStreamPlaylistType.primary,
  backgroundSize: 0,
  playlistVideosSize: 0,
  audioSize: 0,
};

export const mediaSlice = createSlice({
  name: "audio",
  initialState,
  reducers: {
    setMediaSize(
      state,
      action: PayloadAction<{
        backgroundSize: number;
        playlistVideosSize: number;
        audioSize: number;
      }>
    ) {
      const { backgroundSize, playlistVideosSize, audioSize } = action.payload;
      state.backgroundSize = backgroundSize || 0;
      state.playlistVideosSize = playlistVideosSize || 0;
      state.audioSize = audioSize || 0;
    },
    setVisiblePlaylistType(state, action: PayloadAction<IStreamPlaylistType>) {
      state.visiblePlaylistType = action.payload;
    },
    setOverlaysLoading(state, action: PayloadAction<boolean>) {
      state.overlaysLoading = action.payload;
    },
    setBackgroundsLoading(state, action: PayloadAction<boolean>) {
      state.getBackgroundsLoading = action.payload;
    },
    setBackgrounds(state, action: PayloadAction<IVisualMedia[]>) {
      state.backgrounds = action.payload;
    },
    addBackgroundToList(state, action: PayloadAction<IVisualMedia>) {
      state.backgrounds = [action.payload, ...state.backgrounds];
    },
    setSelectedBackgroundId(state, action: PayloadAction<string | null>) {
      state.selectedBackgroundId = action.payload;
    },
    setUploadBackgroundProgress(state, action: PayloadAction<number>) {
      state.uploadBackgroundProgress = action.payload;
    },
    onDeleteBackgroundSuccess(state, action: PayloadAction<string>) {
      state.backgrounds = state.backgrounds.filter(
        (background) => background.id !== action.payload
      );
      if (state.selectedBackgroundId === action.payload) {
        if (state.backgrounds.length) {
          state.selectedBackgroundId = state.backgrounds[0].id;
        } else {
          state.selectedBackgroundId = null;
        }
      }
    },
    setGetItemLoading(state, action: PayloadAction<boolean>) {
      state.getItemsLoading = action.payload;
    },
    onItemSearch(state) {
      state.isSearching = true;
      state.items = [];
    },
    addItemToList(state, action: PayloadAction<IStreamSelectedItem>) {
      state.selectedItems.push(action.payload);
    },
    appendItemToList(
      state,
      action: PayloadAction<Omit<IStreamSelectedItem, "order">>
    ) {
      const lastOrder = getLastOrder(state.selectedItems);
      state.selectedItems.push({ ...action.payload, order: lastOrder + 1 });
    },
    appendItemToJingleList(
      state,
      action: PayloadAction<Omit<IStreamSelectedItem, "order">>
    ) {
      const lastOrder = getLastOrder(state.selectedJingleItems);
      state.selectedJingleItems.push({
        ...action.payload,
        order: lastOrder + 1,
      });
    },
    setCheckboxItemsIds(state, action: PayloadAction<string[]>) {
      state.checkboxItemsIds = action.payload;
    },
    updateSelectedItemChecked(
      state,
      action: PayloadAction<IStreamSelectedItem>
    ) {
      const item = action.payload;
      if (item.id.startsWith(SHOW_ITEM_PREFIX)) {
        const { showId } = parseShowItemId(item.id);
        const showIndex = state.scheduledShows.findIndex(
          (show) => show.id === showId
        );
        if (showIndex > -1) {
          const show = state.scheduledShows[showIndex];
          const itemIndex = show.items.findIndex((it) => it.id === item.id);
          if (itemIndex > -1) {
            state.scheduledShows[showIndex].items[itemIndex].isChecked =
              !state.scheduledShows[showIndex].items[itemIndex].isChecked;
          }
        }
      } else {
        const selectedItemIndex = state.selectedItems.findIndex(
          (audio) => audio.id === action.payload.id
        );
        if (selectedItemIndex > -1) {
          state.selectedItems[selectedItemIndex].isChecked =
            !state.selectedItems[selectedItemIndex].isChecked;
        }
      }
    },
    clearAllSelectedItemsChecked(state) {
      state.selectedItems = state.selectedItems.map((item) => ({
        ...item,
        isChecked: false,
      }));
    },
    setAllSelectedItemsChecked(state) {
      state.selectedItems = state.selectedItems.map((item) => ({
        ...item,
        isChecked: true,
      }));
    },
    addMultipleItemsToList(
      state,
      action: PayloadAction<IStreamSelectedItem[]>
    ) {
      state.selectedItems = state.selectedItems.concat(action.payload);
    },
    addMultipleItemsToJingleList(
      state,
      action: PayloadAction<IStreamSelectedItem[]>
    ) {
      state.selectedJingleItems = state.selectedJingleItems.concat(
        action.payload
      );
    },
    removeItemFromList(state, action: PayloadAction<IStreamSelectedItem>) {
      if (action.payload.id.startsWith(SHOW_ITEM_PREFIX)) {
        const { showId } = parseShowItemId(action.payload.id);
        const showIndex = state.scheduledShows.findIndex(
          (show) => show.id === showId
        );
        if (showIndex > -1) {
          const show = state.scheduledShows[showIndex];
          const itemIndex = show.items.findIndex(
            (it) => it.id === action.payload.id
          );
          if (itemIndex > -1) {
            state.scheduledShows[showIndex].items.splice(itemIndex, 1);
            state.scheduledShows[showIndex].items = state.scheduledShows[
              showIndex
            ].items.map((item, index) => ({ ...item, order: index + 1 }));
          }
        }
      } else if (action.payload.id.startsWith(JINGLE_ITEM_PREFIX)) {
        const newItems = state.selectedJingleItems.filter(
          (audio) => audio.id !== action.payload.id
        );
        newItems.sort((a, b) => a.order - b.order);
        state.selectedJingleItems = newItems.map((item, index) => ({
          ...item,
          order: index + 1,
        }));
      } else {
        const newItems = state.selectedItems.filter(
          (audio) => audio.id !== action.payload.id
        );
        newItems.sort((a, b) => a.order - b.order);
        state.selectedItems = newItems.map((item, index) => ({
          ...item,
          order: index + 1,
        }));
      }
    },
    removeItemFromJingleList(
      state,
      action: PayloadAction<IStreamSelectedItem>
    ) {
      const newItems = state.selectedJingleItems.filter(
        (audio) => audio.id !== action.payload.id
      );
      newItems.sort((a, b) => a.order - b.order);
      state.selectedJingleItems = newItems.map((item, index) => ({
        ...item,
        order: index + 1,
      }));
    },
    removeAllItemsFromList(state) {
      state.selectedItems = [];
    },
    updateUploadItemProgress(
      state,
      action: PayloadAction<{ progress: number; id: string }>
    ) {
      const currentItemIndex = state.pendingUploads.findIndex(
        (audio) => audio.id === action.payload.id
      );
      if (currentItemIndex > -1) {
        state.pendingUploads[currentItemIndex].progress =
          action.payload.progress;
      }
      state.uploadItemProgress = action.payload.progress;
      state.currentItemUploadingId = action.payload.id;
    },
    setCurrentItemUploadingId(state, action: PayloadAction<string | null>) {
      state.currentItemUploadingId = action.payload;
    },
    removePendingUploadItem(state, action: PayloadAction<string>) {
      state.pendingUploads = state.pendingUploads.filter(
        (audio) => audio.id !== action.payload
      );
    },
    clearPendingUploads(state) {
      state.pendingUploads = [];
    },
    setItemPendingUploads(state, action: PayloadAction<IPendingUploadItem[]>) {
      state.pendingUploads = action.payload;
    },
    onUploadFinished(
      state,
      action: PayloadAction<{
        item: IAudio | IVisualMedia;
        addToItems: boolean;
        type: "audio" | "video";
      }>
    ) {
      state.loading = false;
      state.UploadItemLoading = false;
      state.uploadedItem = action.payload.item;

      if (action.payload.addToItems) {
        state.items = [action.payload.item, ...state.items];
      }
      if (state.autoAddToSelected) {
        const lastOrder = getLastOrder(state.selectedItems);
        state.selectedItems.push({
          ...action.payload.item,
          type: action.payload.type,
          order: lastOrder + 1,
        });
      }
    },
    setItem(state, action: PayloadAction<IVisualMedia | IAudio>) {
      if (state.items.some((item) => item.id === action.payload.id)) {
        state.items = state.items.map((item) =>
          item.id === action.payload.id ? action.payload : item
        );
      }
      if (state.backgrounds.some((item) => item.id === action.payload.id)) {
        const bgIndex = state.backgrounds.findIndex(
          (item) => item.id === action.payload.id
        );
        if (bgIndex > -1) {
          state.backgrounds[bgIndex] = action.payload as IVisualMedia;
        }
      }
      if (state.selectedItems.some((item) => item.id === action.payload.id)) {
        const index = state.selectedItems.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index > -1) {
          state.selectedItems[index] = {
            ...state.selectedItems[index],
            duration: action.payload.duration,
            thubmnailUrl: (action.payload as IVisualMedia).thubmnailUrl,
            width: (action.payload as IVisualMedia).width,
            height: (action.payload as IVisualMedia).height,
          };
        }
      }
    },
    cancelItemUpload(state, action) {
      state.pendingUploads = state.pendingUploads.filter(
        (audio) => audio.id !== action.payload
      );
    },
    setSelectedItems(state, action: PayloadAction<IStreamSelectedItem[]>) {
      state.selectedItems = action.payload;
    },
    setSelectedJingleItems(
      state,
      action: PayloadAction<IStreamSelectedItem[]>
    ) {
      state.selectedJingleItems = action.payload;
    },
    setItems(state, action: PayloadAction<IAudio[] | IVisualMedia[]>) {
      state.items = action.payload;
    },
    setAutoAddToSelected(state, action) {
      state.autoAddToSelected = action.payload;
    },
    onDeleteItemSuccess(state, action: PayloadAction<string>) {
      state.items = state.items.filter((audio) => audio.id !== action.payload);
      state.selectedItems = state.selectedItems.filter(
        (audio) => audio.id !== action.payload
      );
    },
    onDuplicateSuccess(state, action: PayloadAction<IAudio | IVisualMedia>) {
      state.items = [action.payload, ...state.items];
    },
    onUpdateItemSuccess(state, action: PayloadAction<IAudio | IVisualMedia>) {
      const itemsIndex = state.items.findIndex(
        (audio) => audio.id === action.payload.id
      );
      if (itemsIndex > -1) {
        state.items[itemsIndex] = {
          ...state.items[itemsIndex],
          ...action.payload,
        };
      }
      const selectedItemIndex = state.selectedItems.findIndex(
        (audio) => audio.id === action.payload.id
      );
      if (selectedItemIndex > -1) {
        state.selectedItems[selectedItemIndex] = {
          ...state.selectedItems[selectedItemIndex],
          name: action.payload.name,
          author: action.payload.author,
          sizeInKB: action.payload.sizeInKB,
        };
      }
    },
    setImportItems(state, action: PayloadAction<any>) {
      state.importItems = action.payload;
    },
    addShow(state, action: PayloadAction<IScheduledShow>) {
      state.scheduledShows = [action.payload, ...state.scheduledShows];
    },
    updateShow(state, action: PayloadAction<IScheduledShow>) {
      const showIndex = state.scheduledShows.findIndex(
        (show) => show.id === action.payload.id
      );
      if (showIndex > -1) {
        state.scheduledShows[showIndex] = { ...action.payload };
      }
    },
    removeShow(state, action: PayloadAction<string>) {
      state.scheduledShows = state.scheduledShows.filter(
        (show) => show.id !== action.payload
      );
    },
    setShows(state, action: PayloadAction<IScheduledShow[]>) {
      state.scheduledShows = action.payload;
    },
    addItemToShowList(
      state,
      action: PayloadAction<{ showId: string; item: IStreamSelectedItem }>
    ) {
      state.scheduledShows = state.scheduledShows.map((show) => {
        if (show.id === action.payload.showId) {
          if (
            show.items.some((item) =>
              item.id.includes(parseShowItemId(action.payload.item.id).itemId)
            )
          ) {
            notification.info({ message: "Item already exists in this show" });
            return show;
          }
          return {
            ...show,
            items: show.items.concat([action.payload.item]),
          };
        }
        return show;
      });
    },
    removeItemFromShowList(
      state,
      action: PayloadAction<{ showId: string; itemId: string }>
    ) {
      state.scheduledShows = state.scheduledShows.map((show) => {
        if (show.id === action.payload.showId) {
          return {
            ...show,
            items: show.items.filter(
              (item) => item.id !== action.payload.itemId
            ),
          };
        }
        return show;
      });
    },
    setShowItems(
      state,
      action: PayloadAction<{ showId: string; items: IStreamSelectedItem[] }>
    ) {
      state.scheduledShows = state.scheduledShows.map((show) => {
        if (show.id === action.payload.showId) {
          return {
            ...show,
            items: action.payload.items,
          };
        }
        return show;
      });
    },
    addMultipleItemsToShowList(
      state,
      action: PayloadAction<{ showId: string; items: IStreamSelectedItem[] }>
    ) {
      state.scheduledShows = state.scheduledShows.map((show) => {
        if (show.id === action.payload.showId) {
          const existingItemsInShowIds = action.payload.items
            .filter((item) =>
              show.items.some((it) =>
                it.id.includes(parseShowItemId(item.id).itemId)
              )
            )
            .map((item) => item.id);
          if (existingItemsInShowIds.length) {
            notification.info({
              message:
                "Some items already exist in this show so will be skipped",
            });
          }
          return {
            ...show,
            items: show.items.concat(
              action.payload.items.filter(
                (item) => !existingItemsInShowIds.includes(item.id)
              )
            ),
          };
        }
        return show;
      });
    },
  },
  extraReducers: {},
});

export const {
  setVisiblePlaylistType,
  setOverlaysLoading,
  setBackgrounds,
  setBackgroundsLoading,
  addBackgroundToList,
  setSelectedBackgroundId,
  setUploadBackgroundProgress,
  onDeleteBackgroundSuccess,
  setGetItemLoading,
  addItemToList,
  appendItemToList,
  appendItemToJingleList,
  addMultipleItemsToList,
  addMultipleItemsToJingleList,
  cancelItemUpload,
  onItemSearch,
  onUploadFinished,
  removeAllItemsFromList,
  removeItemFromList,
  removeItemFromJingleList,
  setItemPendingUploads,
  setItems,
  setItem,
  setAutoAddToSelected,
  setSelectedItems,
  setSelectedJingleItems,
  updateUploadItemProgress,
  removePendingUploadItem,
  setCurrentItemUploadingId,
  clearPendingUploads,
  onDeleteItemSuccess,
  onUpdateItemSuccess,
  setCheckboxItemsIds,
  setImportItems,
  addItemToShowList,
  addShow,
  updateShow,
  removeItemFromShowList,
  removeShow,
  setShows,
  setShowItems,
  updateSelectedItemChecked,
  clearAllSelectedItemsChecked,
  setAllSelectedItemsChecked,
  addMultipleItemsToShowList,
  onDuplicateSuccess,
  setMediaSize,
} = mediaSlice.actions;

export const uploadItem = createAsyncThunk(
  "media/uploadItem",
  async (
    {
      body,
      folderId,
      type,
    }: { body: File; folderId?: string; type: "audio" | "video" },
    { dispatch, getState }
  ) => {
    const { pendingUploads } = (getState() as RootState).media;
    if (pendingUploads.find((audio) => !audio.uploaded && !audio.failed)) {
      return dispatch(
        setItemPendingUploads(
          pendingUploads.concat({
            id: uuid(),
            body,
            folderId,
            progress: 0,
            uploaded: false,
            failed: false,
          })
        )
      );
    }
    const uploadFile = async (file: IPendingUploadItem) => {
      let failed = false;
      if (type === "audio") {
        const form = new FormData();
        form.append("file", file.body);
        const config = {
          onUploadProgress: (progressEvent: {
            loaded?: number;
            total?: number;
          }) => {
            const percentCompletedFloat =
              ((progressEvent.loaded || 0) * 100) /
              (progressEvent.total || 100);
            const percentCompleted = Math.round(percentCompletedFloat);

            const { uploadItemProgress: currentProgress } = (
              getState() as RootState
            ).media;
            if (
              percentCompleted > 1 &&
              percentCompleted < 99 &&
              percentCompleted !== currentProgress
            ) {
              dispatch(
                updateUploadItemProgress({
                  progress: percentCompleted,
                  id: file.id,
                })
              );
            }
          },
          timeout: 1000 * 60 * 60 * 10,
          headers: {
            "Content-Type": file.body.type,
          },
        };

        dispatch(setCurrentItemUploadingId(file.id));

        try {
          const presignedUrl = await axiosInstance.post(
            "/visualMedia/presigned/generate",
            { fileName: file.body.name, contentType: file.body.type }
          );
          await axios.put(presignedUrl.data.url, file.body, config);
          // import key
          const data = await axiosInstance.post<IAudio>(
            "/audio/presigned/save",
            {
              originalName: file.body.name,
              Key: presignedUrl.data.Key,
              folderId: file.folderId,
            }
          );

          const currentFolder = (getState() as RootState).folder.folderById;
          const audioFile = data.data;

          dispatch(
            onUploadFinished({
              item: audioFile,
              addToItems:
                (!currentFolder && !file.folderId) ||
                file.folderId === currentFolder?.id,
              type: "audio",
            })
          );
          setTimeout(() => {
            dispatch(getAudioById(audioFile.id));
            dispatch(getMediaSize());
          }, 6 * 1000);
        } catch (err) {
          failed = true;
        }
      } else if (type === "video") {
        try {
          const uploader = new Uploader({
            fileName: file.body.name,
            file: file.body,
            contentType: file.body.type,
          });
          dispatch(setCurrentItemUploadingId(file.id));
          const { Key, url } = await new Promise<{ Key: string; url: string }>(
            (resolve, reject) => {
              let percentage: undefined | number = undefined;
              uploader
                .onComplete(({ Key, url }) => resolve({ Key, url }))
                .onProgress((data) => {
                  const { percentage: newPercentage } = data;
                  if (
                    newPercentage !== percentage &&
                    Math.floor(newPercentage) < 99
                  ) {
                    percentage = newPercentage;
                    dispatch(
                      updateUploadItemProgress({
                        progress: percentage,
                        id: file.id,
                      })
                    );
                  }
                })
                .onError((err: any) => {
                  console.log("ERROR", err);
                  reject(err);
                });
              uploader.start();
            }
          );

          const data = await axiosInstance.post<IVisualMedia>(
            "/visualMedia/presigned/save",
            {
              classification: "playlistVideo",
              originalName: file.body.name,
              Key: Key,
              folderId: file.folderId,
            }
          );

          const currentFolder = (getState() as RootState).folder.folderById;
          const videoFile = data.data;

          dispatch(
            onUploadFinished({
              item: videoFile,
              addToItems:
                (!currentFolder && !file.folderId) ||
                file.folderId === currentFolder?.id,
              type: "video",
            })
          );
          setTimeout(() => {
            dispatch(getVisualMediaById(videoFile.id));
            dispatch(getMediaSize());
          }, 6 * 1000);
        } catch (err) {
          console.log(err);
          failed = true;
        }
      }

      const { pendingUploads: existingPending } = (getState() as RootState)
        .media;
      const newPending = existingPending.slice();

      const uploadedFileIndex = newPending.findIndex(
        (upload) => upload.id === file.id
      );
      const uploadingFile = {
        ...newPending.find((upload) => upload.id === file.id)!,
      };

      if (failed) {
        uploadingFile.failed = true;
      } else {
        uploadingFile.uploaded = true;
      }

      newPending.splice(uploadedFileIndex, 1, uploadingFile);
      dispatch(setItemPendingUploads(newPending.slice()));

      dispatch(
        updateUploadItemProgress({
          progress: 100,
          id: file.id,
        })
      );

      const newUpload = newPending.find(
        (item) => !item.uploaded && !item.failed
      );
      if (newUpload) {
        uploadFile(newUpload);
      }
    };

    const file = {
      body,
      folderId,
      id: uuid(),
      progress: 0,
      uploaded: false,
    };
    dispatch(setItemPendingUploads([file]));
    return await uploadFile(file);
  }
);

export const updateAudio = createAsyncThunk(
  "media/updateAudio",
  async (
    { id, name, author }: { id: string; name: string; author: string },
    { dispatch }
  ) => {
    dispatch(setGetItemLoading(true));
    try {
      const response = await AudioInstance.updateAudio({ id, name, author });
      dispatch(onUpdateItemSuccess(response.data));
    } catch (err: any) {
      console.log(err);
      notification.error({ message: err.message || "Something went wrong!" });
    }
    dispatch(setGetItemLoading(false));
  }
);

export const getAudioList = createAsyncThunk(
  "media/getAudioList",
  async (
    {
      query = "",
      pageNumber,
      folderId,
    }: { query?: string; pageNumber: number; folderId?: string },
    { dispatch }
  ) => {
    dispatch(setGetItemLoading(true));
    try {
      dispatch(setItems([]));
      const response = await AudioInstance.getAudioList({
        query,
        pageNumber,
        folderId,
      });
      dispatch(setItems(response.data.data));
    } catch (err: any) {
      console.log({ err });
    }
    dispatch(setGetItemLoading(false));
  }
);

export const getAudioById = createAsyncThunk(
  "media/getAudioById",
  async (id: string, { dispatch }) => {
    try {
      const response = await AudioInstance.getAudioById(id);
      dispatch(setItem(response.data));
    } catch (err: any) {
      console.log({ err });
    }
    dispatch(setGetItemLoading(false));
  }
);

export const deleteAudio = createAsyncThunk(
  "media/deleteAudio",
  async (
    { id, refetchMediaSize }: { id: string; refetchMediaSize?: boolean },
    { dispatch }
  ) => {
    dispatch(setGetItemLoading(true));
    try {
      await AudioInstance.deleteAudio(id);
      dispatch(onDeleteItemSuccess(id));
    } catch (err: any) {
      console.log({ err });
      notification.error({ message: err.message || "Something went wrong!" });
    }
    dispatch(setGetItemLoading(false));
    if (refetchMediaSize) {
      dispatch(getMediaSize());
    }
  }
);

export const duplicateAudio = createAsyncThunk(
  "media/duplicateAudio",
  async (
    { id, refetchMediaSize }: { id: string; refetchMediaSize?: boolean },
    { dispatch }
  ) => {
    dispatch(setGetItemLoading(true));
    try {
      const data = await AudioInstance.duplicateAudio(id);
      dispatch(onDuplicateSuccess(data.data));
    } catch (err: any) {
      console.log({ err });
      notification.error({ message: err.message || "Something went wrong!" });
    }
    dispatch(setGetItemLoading(false));
    if (refetchMediaSize) {
      dispatch(getMediaSize());
    }
  }
);

export const duplicateVisualMedia = createAsyncThunk(
  "media/duplicateVisualMedia",
  async (
    { id, refetchMediaSize }: { id: string; refetchMediaSize?: boolean },
    { dispatch }
  ) => {
    dispatch(setGetItemLoading(true));
    try {
      const data = await VisualMediaInstance.duplicateVisualMedia(id);
      dispatch(onDuplicateSuccess(data.data));
    } catch (err: any) {
      console.log({ err });
      notification.error({ message: err.message || "Something went wrong!" });
    }
    dispatch(setGetItemLoading(false));
    if (refetchMediaSize) {
      dispatch(getMediaSize());
    }
  }
);

export const updateVisualMedia = createAsyncThunk(
  "media/updateVisualMedia",
  async (
    { id, name, author }: { id: string; name: string; author: string },
    { dispatch }
  ) => {
    dispatch(setGetItemLoading(true));
    try {
      const response = await VisualMediaInstance.updateVisualMedia({
        id,
        name,
        author,
      });
      dispatch(onUpdateItemSuccess(response.data));
    } catch (err: any) {
      console.log(err);
      notification.error({ message: err.message || "Something went wrong!" });
    }
    dispatch(setGetItemLoading(false));
  }
);

export const getVisualMediaList = createAsyncThunk(
  "media/getVisualMediaList",
  async (
    {
      query = "",
      pageNumber,
      folderId,
      classification,
    }: {
      query?: string;
      pageNumber: number;
      folderId?: string;
      classification: VisualMediaClassificationEnum;
    },
    { dispatch }
  ) => {
    dispatch(setGetItemLoading(true));
    try {
      dispatch(setItems([]));
      const response = await VisualMediaInstance.getVisualMediaList({
        query,
        pageNumber,
        folderId,
        classification,
      });
      dispatch(setItems(response.data.data));
    } catch (err: any) {
      console.log({ err });
    }
    dispatch(setGetItemLoading(false));
  }
);

export const deleteVisualMedia = createAsyncThunk(
  "media/deleteVisualMedia",
  async (
    { id, refetchMediaSize }: { id: string; refetchMediaSize?: boolean },
    { dispatch }
  ) => {
    dispatch(setGetItemLoading(true));
    try {
      await VisualMediaInstance.deleteVisualMedia(id);
      dispatch(onDeleteItemSuccess(id));
    } catch (err: any) {
      console.log({ err });
      notification.error({ message: err.message || "Something went wrong!" });
    }
    dispatch(setGetItemLoading(false));
    if (refetchMediaSize) {
      dispatch(getMediaSize());
    }
  }
);

export const getBackgroundList = createAsyncThunk(
  "media/getBackgroundList",
  async (_, { dispatch }) => {
    dispatch(setBackgroundsLoading(true));
    try {
      // dispatch(setBackgrounds([]));
      const response = await VisualMediaInstance.getVisualMediaList({
        query: "",
        pageNumber: 1,
        classification: VisualMediaClassificationEnum.background,
      });
      dispatch(setBackgrounds(response.data.data));
    } catch (err: any) {
      console.log({ err });
    }
    dispatch(setBackgroundsLoading(false));
  }
);

export const uploadBackground = createAsyncThunk(
  "media/uploadBackground",
  async ({ body }: { body: File }, { dispatch }) => {
    dispatch(setBackgroundsLoading(true));
    try {
      const uploader = new Uploader({
        fileName: body.name,
        file: body,
        contentType: body.type,
      });
      const { Key, url } = await new Promise<{ Key: string; url: string }>(
        (resolve, reject) => {
          let percentage: undefined | number = undefined;
          uploader
            .onComplete(({ Key, url }) => resolve({ Key, url }))
            .onProgress((data) => {
              const { percentage: newPercentage } = data;
              if (
                newPercentage !== percentage &&
                Math.floor(newPercentage) < 99
              ) {
                percentage = newPercentage;
                dispatch(setUploadBackgroundProgress(percentage));
              }
            })
            .onError((err: any) => {
              console.log("ERROR", err);
              reject(err);
            });
          uploader.start();
        }
      );

      // import key
      const data = await axiosInstance.post<IVisualMedia>(
        "/visualMedia/presigned/save",
        {
          classification: VisualMediaClassificationEnum.background,
          originalName: body.name,
          Key: Key,
        }
      );

      const background = data.data;

      dispatch(addBackgroundToList(background));
      dispatch(setSelectedBackgroundId(background.id));
      dispatch(setUploadBackgroundProgress(0));
      setTimeout(() => {
        dispatch(getVisualMediaById(background.id));
        dispatch(getMediaSize());
      }, 6 * 1000);
    } catch (err: any) {
      console.log(err);
      notification.error({ message: err.message || "Something went wrong!" });
    }
    dispatch(setBackgroundsLoading(false));
  }
);

export const deleteBackground = createAsyncThunk(
  "media/deleteBackground",
  async (id: string, { dispatch }) => {
    dispatch(setBackgroundsLoading(true));
    try {
      await VisualMediaInstance.deleteVisualMedia(id);
      dispatch(onDeleteBackgroundSuccess(id));
    } catch (err: any) {
      console.log({ err });
      notification.error({ message: err.message || "Something went wrong!" });
    }
    dispatch(setBackgroundsLoading(false));
  }
);

export const uploadOverlay = createAsyncThunk(
  "media/uploadOverlay",
  async ({ body }: { body: File }, { dispatch }) => {
    dispatch(setOverlaysLoading(true));
    try {
      const form = new FormData();
      form.append("file", body);

      const presignedUrl = await axiosInstance.post(
        "/visualMedia/presigned/generate",
        { fileName: body.name, contentType: body.type }
      );
      await axios.put(presignedUrl.data.url, body, {
        timeout: 1000 * 60 * 60 * 10,
        headers: {
          "Content-Type": body.type,
        },
      });
      const data = await axiosInstance.post<IVisualMedia>(
        "/visualMedia/presigned/save",
        {
          classification: VisualMediaClassificationEnum.overlay,
          originalName: body.name,
          Key: presignedUrl.data.Key,
        }
      );

      dispatch(setOverlaysLoading(false));
      return data.data;
    } catch (err) {
      dispatch(setOverlaysLoading(false));
      throw err;
    }
  }
);

export const getVisualMediaById = createAsyncThunk(
  "media/getVisualMediaById",
  async (id: string, { dispatch }) => {
    try {
      const response = await VisualMediaInstance.getVisualMediaById(id);
      dispatch(setItem(response.data));
    } catch (err: any) {
      console.log({ err });
    }
  }
);

export const getMediaSize = createAsyncThunk(
  "media/getMediaSize",
  async (_, { dispatch }) => {
    try {
      const response = await authServiceInstance.getMediaSize();
      dispatch(setMediaSize(response.data));
    } catch (err: any) {}
  }
);
