import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../helpers/request";
import qs from "querystringify";
import { ILiveStream, IUser, ReferralType } from "../../types/models";

interface AdminState {
  activeLiveStreamsLoading: boolean;
  activeLiveStreams: ILiveStream[];
  usersLoading: boolean;
  users: IUser[];
  usersCount: number;
  referralsLoading: boolean;
  referrals: ReferralType[];
  referralsCount: number;
  trialIncidentsLoading: boolean;
  trialIncidents: any[];
  trialIncidentsCount: number;
  error: null | any;
  userById: null | any;
}

const initialState: AdminState = {
  activeLiveStreamsLoading: false,
  activeLiveStreams: [],
  usersLoading: false,
  users: [],
  usersCount: 0,
  referralsLoading: false,
  referrals: [],
  referralsCount: 0,
  trialIncidentsLoading: false,
  trialIncidents: [],
  trialIncidentsCount: 0,
  error: null,
  userById: null,
};

export const getAdminActiveLiveStreams = createAsyncThunk(
  "getAdminActiveLiveStreams",
  async () => {
    const res = await axiosInstance.get("/admin/live-stream/active");
    return await res.data;
  }
);

export const updateLiveStreamPromoted = createAsyncThunk(
  "updateLiveStreamPromoted",
  async ({ id, promotedAccepted }: { id: string; promotedAccepted: any }) => {
    const res = await axiosInstance.put(
      `/admin/live-stream/${id}/promotedAccepted`,
      { promotedAccepted }
    );
    return await res.data;
  }
);

export const adminRestartLiveStream = createAsyncThunk(
  "adminRestartLiveStream",
  async ({ id }: { id: string }) => {
    const res = await axiosInstance.put(`/admin/live-stream/${id}/restart`);
    return await res.data;
  }
);

export const adminRestartAllLiveStreams = createAsyncThunk(
  "adminRestartAllLiveStreams",
  async (id) => {
    const res = await axiosInstance.post(`/admin/live-stream/restart-all`);
    return await res.data;
  }
);

export const adminEndLiveStream = createAsyncThunk(
  "adminEndLiveStream",
  async ({ id }: { id: string }) => {
    const res = await axiosInstance.put(`/admin/live-stream/${id}/end`);
    return await res.data;
  }
);

export const getAdminUsers = createAsyncThunk(
  "getAdminUsers",
  async (query: any = {}) => {
    const res = await axiosInstance.get(`/admin/users?${qs.stringify(query)}`);
    return await res.data;
  }
);

export const adminChangeUserStatus = createAsyncThunk(
  "adminChangeUserStatus",
  async (data: any) => {
    const res = await axiosInstance.post(`/admin/users/status`, {
      ...data,
    });
    return await res.data;
  }
);

export const adminDeleteUserMedia = createAsyncThunk(
  "adminDeleteUserMedia",
  async (id: string) => {
    const res = await axiosInstance.delete(`/admin/users/${id}/media`);
    return await res.data;
  }
);

export const adminGetUserMedia = createAsyncThunk(
  "adminGetUserMedia",
  async (id: string) => {
    const res = await axiosInstance.delete(`/admin/users/${id}/media?getOnly=true`);
    return await res.data;
  }
);

export const adminDeleteUser = createAsyncThunk(
  "adminDeleteUser",
  async (id: string) => {
    const res = await axiosInstance.delete(`/admin/users/${id}`);
    return await res.data;
  }
);

export const adminImpersonateUser = createAsyncThunk(
  "adminImpersonateUser",
  async (userId: string) => {
    const res = await axiosInstance.post(`/admin/users/impersonate`, {
      userId,
    });
    return await res.data;
  }
);

// Referrals
export const getAdminReferrals = createAsyncThunk(
  "getAdminReferrals",
  async (query: any = {}) => {
    const res = await axiosInstance.get(
      `/admin/referrals?${qs.stringify(query)}`
    );
    return await res.data;
  }
);

export const adminChangeReferralStatus = createAsyncThunk(
  "adminChangeReferralStatus",
  async (data: any) => {
    const res = await axiosInstance.post(`/admin/referrals/status`, {
      ...data,
    });
    return await res.data;
  }
);

// Trial Incidents
export const getAdminTrialIncidents = createAsyncThunk(
  "getAdminTrialIncidents",
  async (query: any = {}) => {
    const res = await axiosInstance.get(
      `/admin/trial-consumed-hash-incident?${qs.stringify(query)}`
    );
    return await res.data;
  }
);

export const AdminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // active live stream
    builder.addCase(getAdminActiveLiveStreams.pending, (state: any) => {
      state.activeLiveStreamsLoading = true;
    });
    builder.addCase(
      getAdminActiveLiveStreams.fulfilled,
      (state: any, { payload }: { payload: any }) => {
        state.activeLiveStreamsLoading = false;
        state.activeLiveStreams = payload;
      }
    );
    builder.addCase(
      getAdminActiveLiveStreams.rejected,
      (state: any, { payload }: { payload: any }) => {
        state.activeLiveStreamsLoading = false;
        state.error = payload;
      }
    );

    // users
    builder.addCase(getAdminUsers.pending, (state: any) => {
      state.usersLoading = true;
    });
    builder.addCase(
      getAdminUsers.fulfilled,
      (state: any, { payload }: { payload: any }) => {
        state.usersLoading = false;
        state.users = payload.users;
        state.usersCount = payload.count;
      }
    );
    builder.addCase(
      getAdminUsers.rejected,
      (state: any, { payload }: { payload: any }) => {
        state.usersLoading = false;
        state.error = payload;
      }
    );

    // referrals
    builder.addCase(getAdminReferrals.pending, (state: any) => {
      state.referralsLoading = true;
    });
    builder.addCase(
      getAdminReferrals.fulfilled,
      (state: any, { payload }: { payload: any }) => {
        state.referralsLoading = false;
        state.referrals = payload.referrals;
        state.referralsCount = payload.count;
      }
    );
    builder.addCase(
      getAdminReferrals.rejected,
      (state: any, { payload }: { payload: any }) => {
        state.referralsLoading = false;
        state.error = payload;
      }
    );

    // trial incidents
    builder.addCase(getAdminTrialIncidents.pending, (state: any) => {
      state.trialIncidentsLoading = true;
    });
    builder.addCase(
      getAdminTrialIncidents.fulfilled,
      (state: any, { payload }: { payload: any }) => {
        state.trialIncidentsLoading = false;
        state.trialIncidents = payload.data;
        state.trialIncidentsCount = payload.count;
      }
    );
    builder.addCase(
      getAdminTrialIncidents.rejected,
      (state: any, { payload }: { payload: any }) => {
        state.trialIncidentsLoading = false;
        state.error = payload;
      }
    );
  },
});

export const AdminReducer = AdminSlice.reducer;
