import { Button, Flex, Typography } from "antd";
import { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";

const RefreshErrorBoundary = ({ children }: { children: ReactNode }) => {
  return (
    <ErrorBoundary
      fallback={
        <div
          style={{
            width: "100%",
            height: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Flex
            gap={10}
            style={{
              alignItems: "center",
              height: "100%",
              color: "white",
            }}
          >
            <Typography style={{ fontSize: 16, color: "white" }}>
              A new update is available. Please try refreshing the page
            </Typography>
            <Button
              type="primary"
              size="small"
              onClick={() => window.location.reload()}
            >
              Refresh
            </Button>
          </Flex>
        </div>
      }
    >
      {children}
    </ErrorBoundary>
  );
};

export default RefreshErrorBoundary;
