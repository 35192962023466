import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FolderTypeEnum,
  ILiveStream,
  ILiveStreamAnalytics,
  ILiveStreamScheduledShow,
  IOverlay,
  IPlaylist,
  IVideo,
  LiveStreamEndOptionEnum,
  LiveStreamJingleEveryTypeEnum,
  LiveStreamOrientationEnum,
  LiveStreamPlaybackOrderEnum,
  LiveStreamStartOptionEnum,
  LiveStreamTypeEnum,
  OverlayTypeEnum,
  VisualMediaTypeEnum,
} from "../../types/models";
import LiveStreamServiceInstance from "../../api/LiveStreamApi";
import {
  ICreateLiveStreamRequest,
  ICreatePlaylistRequest,
  ICreateVideoRequest,
  IOverlaySelectable,
  IScheduledShow,
  IStreamSelectedItem,
  IUpdateLiveStreamRequest,
  IUpdatePlaylistRequest,
  IUpdateVideoRequest,
} from "../../types/interfaces";
import { notification } from "antd";
import { ROUTES_MAP } from "../../routes/routesMap";
import {
  setSelectedBackgroundId,
  setSelectedItems,
  setSelectedJingleItems,
  setShows,
} from "./MediaSlice";
import dayjs from "dayjs";
import {
  getJingleItemId,
  getShowItemId,
  parseJingleItemId,
  parseShowItemId,
} from "../../helpers/helpers";
import {
  DEFAULT_AUDIO_BITRATE,
  DEFAULT_FPS,
  DEFAULT_VIDEO_BITRATE,
  SHOW_ITEM_PREFIX,
} from "../../constants/constants";
import {
  CONTAINER_HEIGHT,
  CONTAINER_WIDTH,
} from "../../Components/OverlayEditor/utils";

interface LiveStreamState {
  activeLiveStreams: ILiveStream[];
  activeLiveStreamsLoading: boolean;
  createLiveSreamLoading: boolean;
  updateLiveStreamLoading: boolean;
  liveStreamByIdLoading: boolean;
  liveStreamById: ILiveStream | null;
  liveStreamByIdAnalytics: ILiveStreamAnalytics[] | null;
  selectedPlatformId: string | null;
  liveStreamForm: {
    id?: string;
    type: "new" | "edit";
    backgroundId: string | null;
    title: string;
    streamType?: LiveStreamTypeEnum;
    playbackOrder: LiveStreamPlaybackOrderEnum;
    streamingAccountId: string;
    streamingPlatformsIds: string[];
    repeatOnEnd: boolean;
    termsAgreedAt: Date | null;
    contentAgreedAt: Date | null;
    startDate?: dayjs.Dayjs;
    endDate?: dayjs.Dayjs;
    startOption: LiveStreamStartOptionEnum;
    endOption: LiveStreamEndOptionEnum;
    overlays: IOverlaySelectable[];
    orientation: LiveStreamOrientationEnum;
    videoWidth: number;
    videoHeight: number;
    videoLeft: number;
    videoTop: number;
    videoBitrate: number;
    videoFPS: number;
    audioBitrate: number;
    jingleEvery: number;
    jingleEveryType: LiveStreamJingleEveryTypeEnum;
    jinglePlaybackOrder: LiveStreamPlaybackOrderEnum;
    crfMode: boolean;
  };
}

const initialState: LiveStreamState = {
  activeLiveStreams: [],
  activeLiveStreamsLoading: false,
  createLiveSreamLoading: false,
  updateLiveStreamLoading: false,
  liveStreamByIdLoading: false,
  liveStreamById: null,
  liveStreamByIdAnalytics: null,
  selectedPlatformId: null,
  liveStreamForm: {
    title: "",
    type: "new",
    backgroundId: "",
    playbackOrder: LiveStreamPlaybackOrderEnum.sequential,
    streamingAccountId: "",
    streamingPlatformsIds: [],
    repeatOnEnd: true,
    startOption: LiveStreamStartOptionEnum.now,
    endOption: LiveStreamEndOptionEnum.never,
    overlays: [],
    termsAgreedAt: null,
    contentAgreedAt: null,
    orientation: LiveStreamOrientationEnum.horizontal,
    videoWidth: CONTAINER_WIDTH,
    videoHeight: CONTAINER_HEIGHT,
    videoLeft: 0,
    videoTop: 0,
    videoBitrate: DEFAULT_VIDEO_BITRATE,
    audioBitrate: DEFAULT_AUDIO_BITRATE,
    videoFPS: DEFAULT_FPS,
    jingleEvery: 5,
    jingleEveryType: LiveStreamJingleEveryTypeEnum.songs,
    jinglePlaybackOrder: LiveStreamPlaybackOrderEnum.sequential,
    crfMode: false,
  },
};

export const liveStreamSlice = createSlice({
  name: "liveStream",
  initialState,
  reducers: {
    setSelectedPlatformId(state, action: PayloadAction<string | null>) {
      state.selectedPlatformId = action.payload;
    },
    setCreateLiveStreamLoading(state, action: PayloadAction<boolean>) {
      state.createLiveSreamLoading = action.payload;
    },
    setUpdateLiveStreamLoading(state, action: PayloadAction<boolean>) {
      state.updateLiveStreamLoading = action.payload;
    },
    setBackgroundId(state, action: PayloadAction<string>) {
      state.liveStreamForm.backgroundId = action.payload;
    },
    setActiveLiveStreams(state, action: PayloadAction<ILiveStream[]>) {
      state.activeLiveStreams = action.payload;
    },
    setActiveLiveStreamsLoading(state, action: PayloadAction<boolean>) {
      state.activeLiveStreamsLoading = action.payload;
    },
    setLiveStreamTitle(state, action: PayloadAction<string>) {
      state.liveStreamForm.title = action.payload;
    },
    setLiveStreamPlaybackOrder(
      state,
      action: PayloadAction<LiveStreamPlaybackOrderEnum>
    ) {
      state.liveStreamForm.playbackOrder = action.payload;
    },
    setLiveStreamJinglePlaybackOrder(
      state,
      action: PayloadAction<LiveStreamPlaybackOrderEnum>
    ) {
      state.liveStreamForm.jinglePlaybackOrder = action.payload;
    },
    setLiveStreamStreamingAccountId(state, action: PayloadAction<string>) {
      state.liveStreamForm.streamingAccountId = action.payload;
    },
    setLiveStreamStreamingPlatformsIds(state, action: PayloadAction<string[]>) {
      state.liveStreamForm.streamingPlatformsIds = action.payload;
    },
    setLiveStreamRepeatOnEnd(state, action: PayloadAction<boolean>) {
      state.liveStreamForm.repeatOnEnd = action.payload;
    },
    setLiveStreamStartOption(
      state,
      action: PayloadAction<LiveStreamStartOptionEnum>
    ) {
      state.liveStreamForm.startOption = action.payload;
    },
    setLiveStreamEndOption(
      state,
      action: PayloadAction<LiveStreamEndOptionEnum>
    ) {
      state.liveStreamForm.endOption = action.payload;
    },
    setLiveStreamOverlays(state, action: PayloadAction<IOverlaySelectable[]>) {
      state.liveStreamForm.overlays = action.payload;
    },
    setLiveStreamStartDate(state, action: PayloadAction<dayjs.Dayjs>) {
      state.liveStreamForm.startDate = action.payload;
    },
    setLiveStreamEndDate(state, action: PayloadAction<dayjs.Dayjs>) {
      state.liveStreamForm.endDate = action.payload;
    },
    setLiveStreamTermsAgreedAt(state, action: PayloadAction<Date | null>) {
      state.liveStreamForm.termsAgreedAt = action.payload;
    },
    setLiveStreamContentAgreedAt(state, action: PayloadAction<Date | null>) {
      state.liveStreamForm.contentAgreedAt = action.payload;
    },
    setLiveStreamId(state, action: PayloadAction<string>) {
      state.liveStreamForm.id = action.payload;
    },
    setLiveStreamType(state, action: PayloadAction<LiveStreamTypeEnum>) {
      state.liveStreamForm.streamType = action.payload;
    },
    setLiveStreamOrientation(
      state,
      action: PayloadAction<LiveStreamOrientationEnum>
    ) {
      state.liveStreamForm.orientation = action.payload;
    },
    setVideoSettings(
      state,
      action: PayloadAction<{
        width: number;
        height: number;
        left: number;
        top: number;
      }>
    ) {
      state.liveStreamForm.videoWidth = action.payload.width;
      state.liveStreamForm.videoHeight = action.payload.height;
      state.liveStreamForm.videoLeft = action.payload.left;
      state.liveStreamForm.videoTop = action.payload.top;
    },
    setVideoBitrate(state, action: PayloadAction<number>) {
      state.liveStreamForm.videoBitrate = action.payload;
    },
    setAudioBitrate(state, action: PayloadAction<number>) {
      state.liveStreamForm.audioBitrate = action.payload;
    },
    setVideoFPS(state, action: PayloadAction<number>) {
      state.liveStreamForm.videoFPS = action.payload;
    },
    resetLiveStreamForm(state) {
      state.liveStreamForm = { ...initialState.liveStreamForm };
    },
    setLiveStreamForm(
      state,
      action: PayloadAction<LiveStreamState["liveStreamForm"]>
    ) {
      state.liveStreamForm = action.payload;
    },
    setLiveStreamByIdLoading(state, action: PayloadAction<boolean>) {
      state.liveStreamByIdLoading = action.payload;
    },
    setLiveStreamById(state, action: PayloadAction<ILiveStream | null>) {
      state.liveStreamById = action.payload;
    },
    setLiveStreamByIdAnalytics(
      state,
      action: PayloadAction<ILiveStreamAnalytics[] | null>
    ) {
      state.liveStreamByIdAnalytics = action.payload;
    },
    setJingleEvery(state, action: PayloadAction<number>) {
      state.liveStreamForm.jingleEvery = action.payload;
    },
    setJingleEveryType(
      state,
      action: PayloadAction<LiveStreamJingleEveryTypeEnum>
    ) {
      state.liveStreamForm.jingleEveryType = action.payload;
    },
    setCRFMode(state, action: PayloadAction<boolean>) {
      state.liveStreamForm.crfMode = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  setBackgroundId,
  setActiveLiveStreams,
  setLiveStreamOrientation,
  setVideoSettings,
  setVideoBitrate,
  setAudioBitrate,
  setVideoFPS,
  setActiveLiveStreamsLoading,
  setLiveStreamTitle,
  setLiveStreamPlaybackOrder,
  setLiveStreamStreamingAccountId,
  setLiveStreamStreamingPlatformsIds,
  setLiveStreamRepeatOnEnd,
  setLiveStreamStartOption,
  setLiveStreamEndOption,
  setLiveStreamOverlays,
  setLiveStreamStartDate,
  setLiveStreamEndDate,
  setLiveStreamTermsAgreedAt,
  setLiveStreamContentAgreedAt,
  setLiveStreamId,
  setLiveStreamByIdAnalytics,
  setLiveStreamType,
  resetLiveStreamForm,
  setLiveStreamForm,
  setCreateLiveStreamLoading,
  setUpdateLiveStreamLoading,
  setLiveStreamById,
  setLiveStreamByIdLoading,
  setSelectedPlatformId,
  setJingleEvery,
  setJingleEveryType,
  setLiveStreamJinglePlaybackOrder,
  setCRFMode,
} = liveStreamSlice.actions;

export const getActiveLiveStreams = createAsyncThunk(
  "liveStream/getActiveLiveStreams",
  async (silent: boolean, { dispatch }) => {
    if (!silent) {
      dispatch(setActiveLiveStreamsLoading(true));
    }
    try {
      const response = await LiveStreamServiceInstance.getActiveLiveStreams();
      dispatch(setActiveLiveStreams(response.data));
    } catch (err: any) {
      console.log({ err });
    }
    if (!silent) {
      dispatch(setActiveLiveStreamsLoading(false));
    }
  }
);
export const getLiveStreamsAnalytics = createAsyncThunk(
  "liveStream/getLiveStreamsAnalytics",
  async (
    {
      id,
      startDate,
      endDate,
    }: { id: string; startDate: string; endDate: string },
    { dispatch }
  ) => {
    try {
      const response =
        await LiveStreamServiceInstance.getLiveStreamAnalyticsById(
          id,
          startDate,
          endDate
        );
      dispatch(setLiveStreamByIdAnalytics(response.data));
    } catch (err: any) {
      console.log({ err });
    }
  }
);

export const populateLiveStreamSettingsFromLiveStream = createAsyncThunk(
  "liveStream/populateLiveStreamSettingsFromLiveStream",
  async ({ liveStream }: { liveStream: ILiveStream }, { dispatch }) => {
    let title = "";
    if (
      liveStream.liveStreamContentItems[0].type === LiveStreamTypeEnum.video &&
      liveStream.liveStreamContentItems[0].video
    ) {
      title = liveStream.liveStreamContentItems[0].video.name;
      dispatch(
        setSelectedItems(
          liveStream.liveStreamContentItems[0].video.audios
            .slice()
            .sort((a, b) => a.order - b.order)
            .map((audio) => ({
              ...audio.audio,
              order: audio.order,
              type: "audio",
            }))
        )
      );
      dispatch(
        setSelectedBackgroundId(
          liveStream.liveStreamContentItems[0].video?.backgrounds[0]
            ?.visualMediaId || ""
        )
      );
      if (liveStream.jingleVideo) {
        dispatch(
          setSelectedJingleItems(
            liveStream.jingleVideo.audios
              .slice()
              .sort((a, b) => a.order - b.order)
              .map((audio) => ({
                ...audio.audio,
                id: getJingleItemId(audio.audio.id),
                order: audio.order,
                type: "audio",
              }))
          )
        );
      } else {
        dispatch(setSelectedJingleItems([]));
      }
    } else if (
      liveStream.liveStreamContentItems[0].type ===
        LiveStreamTypeEnum.playlist &&
      liveStream.liveStreamContentItems[0].playlist
    ) {
      title = liveStream.liveStreamContentItems[0].playlist.name;
      dispatch(
        setSelectedItems(
          liveStream.liveStreamContentItems[0].playlist.playlistVisualMedias
            .slice()
            .sort((a, b) => a.order - b.order)
            .map((visualMedia) => ({
              ...visualMedia.visualMedia,
              order: visualMedia.order,
              url: visualMedia.visualMedia.url || "",
              thubmnailUrl: visualMedia.visualMedia.thubmnailUrl || "",
              type: "video",
            }))
        )
      );
      if (liveStream.jinglePlaylist) {
        dispatch(
          setSelectedJingleItems(
            liveStream.jinglePlaylist.playlistVisualMedias
              .slice()
              .sort((a, b) => a.order - b.order)
              .map((visualMedia) => ({
                ...visualMedia.visualMedia,
                id: getJingleItemId(visualMedia.visualMedia.id),
                order: visualMedia.order,
                url: visualMedia.visualMedia.url || "",
                thubmnailUrl: visualMedia.visualMedia.thubmnailUrl || "",
                type: "video",
              }))
          )
        );
      } else {
        dispatch(setSelectedJingleItems([]));
      }
    }

    dispatch(
      setShows(
        liveStream.liveStreamScheduledShows.map((show) => ({
          id: show.id,
          name: show.name,
          startTime: new Date(show.startTime),
          playlistId: show.playlistId,
          videoId: show.videoId,
          backgroundId: show.video?.backgrounds[0]?.visualMediaId || "",
          switchMode: show.switchMode || "wait",
          items: show.playlist
            ? show.playlist?.playlistVisualMedias
                .slice()
                .sort((a, b) => a.order - b.order)
                .map((visualMedia) => ({
                  ...visualMedia.visualMedia,
                  id: getShowItemId(show.id, visualMedia.visualMedia.id),
                  type: FolderTypeEnum.video,
                  url: visualMedia.visualMedia.url || "",
                  thubmnailUrl: visualMedia.visualMedia.thubmnailUrl || "",
                  order: visualMedia.order,
                }))
            : show.video
            ? show.video?.audios
                .slice()
                .sort((a, b) => a.order - b.order)
                .map((audio) => ({
                  ...audio.audio,
                  id: getShowItemId(show.id, audio.audio.id),
                  type: FolderTypeEnum.audio,
                  order: audio.order,
                }))
            : [],
        }))
      )
    );

    dispatch(setSelectedPlatformId(liveStream.liveStreamPlatforms[0].id!));
    dispatch(
      setLiveStreamForm({
        id: liveStream.id,
        type: "edit",
        backgroundId:
          liveStream.liveStreamContentItems[0].video?.backgrounds[0]
            ?.visualMediaId || "",
        overlays: (
          liveStream.liveStreamContentItems[0].video?.overlays ||
          liveStream.liveStreamContentItems[0].playlist?.overlays ||
          []
        ).map((overlay) => ({ ...overlay, isSelected: false })),
        playbackOrder: liveStream.playbackOrder,
        repeatOnEnd: liveStream.repeatOnEnd,
        streamingAccountId: liveStream.streamingAccountId,
        streamingPlatformsIds: liveStream.liveStreamPlatforms.map(
          (platform) => platform.streamingPlatformId!
        ),
        title,
        startOption: liveStream.startOption,
        endOption: liveStream.endOption,
        startDate: liveStream.startDate
          ? dayjs(liveStream.startDate)
          : undefined,
        endDate: liveStream.endDate ? dayjs(liveStream.endDate) : undefined,
        streamType: liveStream.liveStreamContentItems[0].type,
        termsAgreedAt: new Date(liveStream.termsAgreedAt!),
        contentAgreedAt: new Date(liveStream.contentAgreedAt!),
        orientation:
          liveStream.orientation || LiveStreamOrientationEnum.horizontal,
        videoWidth: liveStream.videoWidth || CONTAINER_WIDTH,
        videoHeight: liveStream.videoHeight || CONTAINER_HEIGHT,
        videoLeft: liveStream.videoLeft || 0,
        videoTop: liveStream.videoTop || 0,
        videoBitrate: liveStream.videoBitrate || 5000,
        audioBitrate: liveStream.audioBitrate || 320,
        videoFPS: liveStream.videoFPS || 30,
        jingleEvery: liveStream.jingleEvery || 60,
        jingleEveryType:
          liveStream.jingleEveryType || LiveStreamJingleEveryTypeEnum.minutes,
        jinglePlaybackOrder:
          liveStream.jinglePlaybackOrder ||
          LiveStreamPlaybackOrderEnum.sequential,
        crfMode: liveStream.crfMode || false,
      })
    );
  }
);

export const getLiveStreamById = createAsyncThunk(
  "liveStream/getLiveStreamById",
  async ({ id, silent }: { id: string; silent: boolean }, { dispatch }) => {
    if (!silent) {
      dispatch(setLiveStreamByIdLoading(true));
    }
    try {
      const { data: liveStream } =
        await LiveStreamServiceInstance.getLiveStreamById(id);
      dispatch(setLiveStreamById(liveStream));
      if (!silent) {
        dispatch(populateLiveStreamSettingsFromLiveStream({ liveStream }));
      }
    } catch (err: any) {
      console.log({ err });
    }
    if (!silent) {
      dispatch(setLiveStreamByIdLoading(false));
    }
  }
);

export const createLiveStream = createAsyncThunk(
  "liveStream/createLiveStream",
  async (
    {
      form,
      selectedItems,
      scheduledShows,
      selectedJingleItems,
    }: {
      form: LiveStreamState["liveStreamForm"];
      selectedItems: IStreamSelectedItem[];
      scheduledShows: IScheduledShow[];
      selectedJingleItems: IStreamSelectedItem[];
    },
    { dispatch, getState }
  ) => {
    dispatch(setCreateLiveStreamLoading(true));
    try {
      // First create a stream radio/video loop
      // Then create a live stream
      let contentItem:
        | ICreateLiveStreamRequest["liveStreamContentItems"][0]
        | null = null;
      let liveStreamScheduledShows: ILiveStreamScheduledShow[] = [];
      let jinglePlaylistId = "";
      let jingleVideoId = "";
      const jinglePlaylistName = `jingle_${Date.now()}`;

      const overlays = (form.overlays || [])
        .filter(
          (overlay) =>
            ([
              OverlayTypeEnum.gif,
              OverlayTypeEnum.image,
              OverlayTypeEnum.video,
            ].includes(overlay.type) &&
              overlay.visualMediaId) ||
            [
              OverlayTypeEnum.text,
              OverlayTypeEnum.song_title,
              OverlayTypeEnum.song_author,
            ].includes(overlay.type)
        )
        .map((overlay) => ({
          ...overlay,
          isSelected: undefined,
          visualMedia: undefined,
          url: undefined,
          id: undefined,
          videoId: undefined,
          playlistId: undefined,
        }));
      if (form.streamType === LiveStreamTypeEnum.video) {
        const data: ICreateVideoRequest = {
          name: form.title,
          fadeBetweenBackgrounds: false,
          export: false,
          backgrounds: [
            {
              visualMediaId: form.backgroundId!,
              order: 0,
              startSecond: 0,
              endSecond: 20,
            },
          ],
          audios: selectedItems.map((item) => ({
            audioId: item.id,
            order: item.order,
          })),
          overlays,
        };
        const video = await LiveStreamServiceInstance.createVideo(data);
        const newVideos = scheduledShows.map((show) => ({
          name: show.name,
          fadeBetweenBackgrounds: false,
          export: false,
          backgrounds: [
            {
              visualMediaId: show.backgroundId || form.backgroundId!,
              order: 0,
              startSecond: 0,
              endSecond: 20,
            },
          ],
          audios: show.items.map((item) => ({
            audioId: parseShowItemId(item.id).itemId,
            order: item.order,
          })),
          overlays,
        }));
        if (selectedJingleItems.length) {
          newVideos.push({
            name: jinglePlaylistName,
            fadeBetweenBackgrounds: false,
            export: false,
            backgrounds: [
              {
                visualMediaId: form.backgroundId!,
                order: 0,
                startSecond: 0,
                endSecond: 20,
              },
            ],
            audios: selectedJingleItems.map((item) => ({
              audioId: parseJingleItemId(item.id),
              order: item.order,
            })),
            overlays,
          });
        }

        if (newVideos.length) {
          const showsVideos = (
            await LiveStreamServiceInstance.batchCreateVideo(newVideos)
          ).data;
          showsVideos.forEach((video) => {
            const show = scheduledShows.find(
              (show) => show.name === video.name
            );
            if (show) {
              liveStreamScheduledShows.push({
                id: show.id,
                name: show.name,
                startTime: show.startTime,
                videoId: video.id,
                switchMode: show.switchMode || "wait",
              });
            }
          });

          jingleVideoId =
            showsVideos.find((video) => video.name === jinglePlaylistName)
              ?.id || "";
        }
        contentItem = {
          order: 0,
          type: form.streamType,
          videoId: video.data.id,
          playlistId: null,
        };
      } else if (form.streamType === LiveStreamTypeEnum.playlist) {
        const data: ICreatePlaylistRequest = {
          name: form.title,
          playlistVisualMedias: selectedItems.map((item) => ({
            visualMediaId: item.id,
            order: item.order,
          })),
          overlays,
        };
        const playlist = await LiveStreamServiceInstance.createPlaylist(data);
        const newPlaylists = scheduledShows.map((show) => ({
          name: show.name,
          playlistVisualMedias: show.items.map((item) => ({
            visualMediaId: parseShowItemId(item.id).itemId,
            order: item.order,
          })),
          overlays,
        }));
        if (selectedJingleItems.length) {
          newPlaylists.push({
            name: jinglePlaylistName,
            playlistVisualMedias: selectedJingleItems.map((item) => ({
              visualMediaId: parseJingleItemId(item.id),
              order: item.order,
            })),
            overlays,
          });
        }

        if (newPlaylists.length) {
          const showPlaylists =
            await LiveStreamServiceInstance.batchCreatePlaylist(newPlaylists);
          showPlaylists.data.forEach((playlist) => {
            const show = scheduledShows.find(
              (show) => show.name === playlist.name
            );
            if (show) {
              liveStreamScheduledShows.push({
                id: show.id,
                name: show.name,
                startTime: show.startTime,
                playlistId: playlist.id,
                switchMode: show.switchMode || "wait",
              });
            }
          });
          jinglePlaylistId =
            showPlaylists.data.find(
              (playlist) => playlist.name === jinglePlaylistName
            )?.id || "";
        }
        contentItem = {
          order: 0,
          type: form.streamType,
          playlistId: playlist.data.id,
          videoId: null,
        };
      }
      const liveStreamData: ICreateLiveStreamRequest = {
        streamingPlatformsIds: form.streamingPlatformsIds,
        streamingAccountId: form.streamingAccountId,
        liveStreamContentItems: [contentItem!],
        repeatOnEnd: true,
        termsAgreedAt: form.termsAgreedAt!,
        contentAgreedAt: form.contentAgreedAt!,
        startDate: form.startDate?.toDate(),
        endDate: form.endDate?.toDate(),
        startOption: form.startOption,
        endOption: form.endOption,
        playbackOrder: form.playbackOrder,
        videoWidth: form.videoWidth,
        videoHeight: form.videoHeight,
        videoLeft: form.videoLeft,
        videoTop: form.videoTop,
        orientation: form.orientation,
        videoBitrate: form.videoBitrate,
        videoFPS: form.videoFPS,
        audioBitrate: form.audioBitrate,
        jingleEvery: form.jingleEvery,
        jingleEveryType: form.jingleEveryType,
        jinglePlaybackOrder: form.jinglePlaybackOrder,
        jinglePlaylistId,
        jingleVideoId,
        liveStreamScheduledShows,
        crfMode: form.crfMode,
      };
      const liveStream = await LiveStreamServiceInstance.createLiveStream(
        liveStreamData
      );
      notification.success({ message: "Started live stream successfully" });
      window.location.href = `${ROUTES_MAP.dashboard.liveStreamDetails(
        liveStream.data.id
      )}?activated=true`;
    } catch (err: any) {
      console.log({ err });
      notification.error({ message: err.message || "Something went wrong" });
    }
    dispatch(setCreateLiveStreamLoading(false));
  }
);

export const updateLiveStream = createAsyncThunk(
  "liveStream/updateLiveStream",
  async (
    {
      id,
      videoId,
      playlistId,
      jingleVideoId,
      jinglePlaylistId,
      form,
      selectedItems,
      selectedJingleItems,
      scheduledShows,
    }: {
      id: string;
      videoId?: string;
      playlistId?: string;
      jingleVideoId?: string;
      jinglePlaylistId?: string;
      form: LiveStreamState["liveStreamForm"];
      selectedItems: IStreamSelectedItem[];
      scheduledShows: IScheduledShow[];
      selectedJingleItems: IStreamSelectedItem[];
    },
    { dispatch }
  ) => {
    dispatch(setCreateLiveStreamLoading(true));
    try {
      // First create a stream radio/video loop
      // Then create a live stream
      let contentItem:
        | ICreateLiveStreamRequest["liveStreamContentItems"][0]
        | null = null;
      let liveStreamScheduledShows: ILiveStreamScheduledShow[] = [];

      const overlays = (form.overlays || [])
        .filter(
          (overlay) =>
            ([
              OverlayTypeEnum.gif,
              OverlayTypeEnum.image,
              OverlayTypeEnum.video,
            ].includes(overlay.type) &&
              overlay.visualMediaId) ||
            [
              OverlayTypeEnum.text,
              OverlayTypeEnum.song_title,
              OverlayTypeEnum.song_author,
            ].includes(overlay.type)
        )
        .map((overlay) => ({
          ...overlay,
          isSelected: undefined,
          visualMedia: undefined,
          url: undefined,
          id: undefined,
          videoId: undefined,
          playlistId: undefined,
        }));
      if (form.streamType === LiveStreamTypeEnum.video) {
        let video: IVideo | null = null;
        if (videoId) {
          const data: IUpdateVideoRequest = {
            id: videoId,
            name: form.title,
            fadeBetweenBackgrounds: false,
            export: false,
            backgrounds: [
              {
                visualMediaId: form.backgroundId!,
                order: 0,
                startSecond: 0,
                endSecond: 20,
              },
            ],
            audios: selectedItems.map((item) => ({
              audioId: item.id,
              order: item.order,
            })),
            overlays,
          };
          video = (await LiveStreamServiceInstance.updateVideo(data)).data;
        } else {
          const data: ICreateVideoRequest = {
            name: form.title,
            fadeBetweenBackgrounds: false,
            export: false,
            backgrounds: [
              {
                visualMediaId: form.backgroundId!,
                order: 0,
                startSecond: 0,
                endSecond: 20,
              },
            ],
            audios: selectedItems.map((item) => ({
              audioId: item.id,
              order: item.order,
            })),
            overlays,
          };
          video = (await LiveStreamServiceInstance.createVideo(data)).data;
          videoId = video.id;
        }

        if (scheduledShows.length) {
          const newShowsVideos = scheduledShows.filter((show) =>
            show.id.startsWith("new")
          );
          const existingShowsVideos = scheduledShows.filter(
            (show) => !show.id.startsWith("new")
          );

          if (newShowsVideos.length) {
            const newShowsVideosData = (
              await LiveStreamServiceInstance.batchCreateVideo(
                newShowsVideos.map((show) => ({
                  name: show.name,
                  fadeBetweenBackgrounds: false,
                  export: false,
                  backgrounds: [
                    {
                      visualMediaId: show.backgroundId || form.backgroundId!,
                      order: 0,
                      startSecond: 0,
                      endSecond: 20,
                    },
                  ],
                  audios: show.items.map((item) => ({
                    audioId: parseShowItemId(item.id).itemId,
                    order: item.order,
                  })),
                  overlays,
                }))
              )
            ).data;
            newShowsVideosData.forEach((video) => {
              const show = scheduledShows.find(
                (show) => show.name === video.name
              );
              if (show) {
                liveStreamScheduledShows.push({
                  id: show.id,
                  name: show.name,
                  startTime: show.startTime,
                  videoId: video.id,
                  switchMode: show.switchMode || "wait",
                });
              }
            });
          }
          if (existingShowsVideos.length) {
            const existingShowsVideosData = (
              await LiveStreamServiceInstance.batchUpdateVideos(
                existingShowsVideos.map((show) => ({
                  id: show.videoId!,
                  name: show.name,
                  fadeBetweenBackgrounds: false,
                  export: false,
                  backgrounds: [
                    {
                      visualMediaId: show.backgroundId || form.backgroundId!,
                      order: 0,
                      startSecond: 0,
                      endSecond: 20,
                    },
                  ],
                  audios: show.items.map((item) => ({
                    audioId: parseShowItemId(item.id).itemId,
                    order: item.order,
                  })),
                  overlays,
                }))
              )
            ).data;
            existingShowsVideosData.forEach((video) => {
              const show = scheduledShows.find(
                (show) => show.videoId === video.id
              );
              if (show) {
                liveStreamScheduledShows.push({
                  id: show.id,
                  name: show.name,
                  startTime: show.startTime,
                  videoId: video.id,
                  switchMode: show.switchMode || "wait",
                });
              }
            });
          }
        }
        contentItem = {
          order: 0,
          type: form.streamType,
          videoId: video.id,
          playlistId: null,
        };

        if (jingleVideoId) {
          if (selectedJingleItems.length) {
            const jingleData: IUpdateVideoRequest = {
              id: jingleVideoId,
              name: `jingle_${Date.now()}`,
              fadeBetweenBackgrounds: false,
              export: false,
              backgrounds: [
                {
                  visualMediaId: form.backgroundId!,
                  order: 0,
                  startSecond: 0,
                  endSecond: 20,
                },
              ],
              audios: selectedJingleItems.map((item) => ({
                audioId: parseJingleItemId(item.id),
                order: item.order,
              })),
              overlays,
            };
            await LiveStreamServiceInstance.updateVideo(jingleData);
          } else {
            jingleVideoId = "";
          }
        } else if (selectedJingleItems.length) {
          const jingleData: ICreateVideoRequest = {
            name: `jingle_${Date.now()}`,
            fadeBetweenBackgrounds: false,
            export: false,
            backgrounds: [
              {
                visualMediaId: form.backgroundId!,
                order: 0,
                startSecond: 0,
                endSecond: 20,
              },
            ],
            audios: selectedJingleItems.map((item) => ({
              audioId: parseJingleItemId(item.id),
              order: item.order,
            })),
            overlays,
          };
          const jingle = await LiveStreamServiceInstance.createVideo(
            jingleData
          );
          jingleVideoId = jingle.data.id;
        }
      } else if (form.streamType === LiveStreamTypeEnum.playlist) {
        let playlist: IPlaylist | null = null;

        if (playlistId) {
          const data: IUpdatePlaylistRequest = {
            id: playlistId,
            name: form.title,
            playlistVisualMedias: selectedItems.map((item) => ({
              visualMediaId: item.id,
              order: item.order,
            })),
            overlays,
          };
          playlist = (await LiveStreamServiceInstance.updatePlaylist(data))
            .data;
        } else {
          const data: ICreatePlaylistRequest = {
            name: form.title,
            playlistVisualMedias: selectedItems.map((item) => ({
              visualMediaId: item.id,
              order: item.order,
            })),
            overlays,
          };

          playlist = (await LiveStreamServiceInstance.createPlaylist(data))
            .data;
          playlistId = playlist.id;
        }

        if (scheduledShows.length) {
          const newShowsPlaylists = scheduledShows.filter((show) =>
            show.id.startsWith("new")
          );
          const existingShowsPlaylists = scheduledShows.filter(
            (show) => !show.id.startsWith("new")
          );

          if (newShowsPlaylists.length) {
            const newShowsPlaylistsData = (
              await LiveStreamServiceInstance.batchCreatePlaylist(
                newShowsPlaylists.map((show) => ({
                  name: show.name,
                  playlistVisualMedias: show.items.map((item) => ({
                    visualMediaId: parseShowItemId(item.id).itemId,
                    order: item.order,
                  })),
                  overlays,
                }))
              )
            ).data;
            newShowsPlaylistsData.forEach((playlist) => {
              const show = scheduledShows.find(
                (show) => show.name === playlist.name
              );
              if (show) {
                liveStreamScheduledShows.push({
                  id: show.id,
                  name: show.name,
                  startTime: show.startTime,
                  playlistId: playlist.id,
                  switchMode: show.switchMode || "wait",
                });
              }
            });
          }
          if (existingShowsPlaylists.length) {
            const existingShowsPlaylistsData = (
              await LiveStreamServiceInstance.batchUpdatePlaylists(
                existingShowsPlaylists.map((show) => ({
                  id: show.playlistId!,
                  name: show.name,
                  playlistVisualMedias: show.items.map((item) => ({
                    visualMediaId: parseShowItemId(item.id).itemId,
                    order: item.order,
                  })),
                  overlays,
                }))
              )
            ).data;

            existingShowsPlaylistsData.forEach((playlist) => {
              const show = scheduledShows.find(
                (show) => show.playlistId === playlist.id
              );
              if (show) {
                liveStreamScheduledShows.push({
                  id: show.id,
                  name: show.name,
                  startTime: show.startTime,
                  playlistId: playlist.id,
                  switchMode: show.switchMode || "wait",
                });
              }
            });
          }
        }

        contentItem = {
          order: 0,
          type: form.streamType,
          playlistId: playlist.id,
          videoId: null,
        };

        if (jinglePlaylistId) {
          if (selectedJingleItems.length) {
            const jingleData: IUpdatePlaylistRequest = {
              id: jinglePlaylistId,
              name: `jingle_${Date.now()}`,
              playlistVisualMedias: selectedJingleItems.map((item) => ({
                visualMediaId: parseJingleItemId(item.id),
                order: item.order,
              })),
              overlays,
            };
            await LiveStreamServiceInstance.updatePlaylist(jingleData);
          } else {
            jinglePlaylistId = "";
          }
        } else if (selectedJingleItems.length) {
          const jingleData: ICreatePlaylistRequest = {
            name: `jingle_${Date.now()}`,
            playlistVisualMedias: selectedJingleItems.map((item) => ({
              visualMediaId: parseJingleItemId(item.id),
              order: item.order,
            })),
            overlays,
          };
          const jingle = await LiveStreamServiceInstance.createPlaylist(
            jingleData
          );
          jinglePlaylistId = jingle.data.id;
        }
      }
      const liveStreamData: IUpdateLiveStreamRequest = {
        id: id,
        streamingPlatformsIds: form.streamingPlatformsIds,
        streamingAccountId: form.streamingAccountId,
        liveStreamContentItems: [contentItem!],
        repeatOnEnd: true,
        termsAgreedAt: form.termsAgreedAt!,
        contentAgreedAt: form.contentAgreedAt!,
        startDate: form.startDate?.toDate(),
        endDate: form.endDate?.toDate(),
        startOption: form.startOption,
        endOption: form.endOption,
        playbackOrder: form.playbackOrder,
        videoWidth: form.videoWidth,
        videoHeight: form.videoHeight,
        videoLeft: form.videoLeft,
        videoTop: form.videoTop,
        orientation: form.orientation,
        videoBitrate: form.videoBitrate,
        videoFPS: form.videoFPS,
        audioBitrate: form.audioBitrate,
        jingleEvery: form.jingleEvery,
        jingleEveryType: form.jingleEveryType,
        jinglePlaybackOrder: form.jinglePlaybackOrder,
        jinglePlaylistId,
        jingleVideoId,
        liveStreamScheduledShows,
        crfMode: form.crfMode,
      };
      await LiveStreamServiceInstance.updateLiveStream(liveStreamData);
      notification.success({ message: "Updated live stream successfully" });
      dispatch(getLiveStreamById({ id, silent: false }));
    } catch (err: any) {
      console.log({ err });
    }
    dispatch(setCreateLiveStreamLoading(false));
  }
);

export const updateVideoKey = createAsyncThunk(
  "liveStream/updateVideoKey",
  async (
    {
      id,
      platformId,
      videoKey,
    }: { id: string; platformId: string; videoKey: string },
    { dispatch }
  ) => {
    try {
      await LiveStreamServiceInstance.updateVideoKey({
        id,
        platformId,
        videoKey,
      });
      await dispatch(getLiveStreamById({ id, silent: true }));
      notification.success({ message: "Analytics connected successfully!" });
    } catch (err: any) {
      console.log({ err });
    }
  }
);

export const updatePromoted = createAsyncThunk(
  "liveStream/updatePromoted",
  async (
    {
      id,
      promoted,
      promotedUrl,
    }: { id: string; promoted: boolean; promotedUrl: string },
    { dispatch }
  ) => {
    try {
      await LiveStreamServiceInstance.updatePromoted({
        id,
        promoted,
        promotedUrl,
      });
      await dispatch(getLiveStreamById({ id, silent: true }));
      notification.success({ message: "Update successfully!" });
    } catch (err: any) {
      console.log({ err });
    }
  }
);

export const restartLiveStream = createAsyncThunk(
  "liveStream/restartLiveStream",
  async (id: string, { dispatch }) => {
    try {
      await LiveStreamServiceInstance.restartLiveStream(id);
      await dispatch(getLiveStreamById({ id, silent: true }));
      notification.success({ message: "Live stream restarted successfully!" });
    } catch (err: any) {
      console.log({ err });
    }
  }
);

export const endLiveStream = createAsyncThunk(
  "liveStream/endLiveStream",
  async (id: string, { dispatch }) => {
    try {
      await LiveStreamServiceInstance.endLiveStream(id);
      await dispatch(getLiveStreamById({ id, silent: true }));
      notification.success({ message: "Live stream ended successfully!" });
    } catch (err: any) {
      console.log({ err });
    }
  }
);

export const stopLiveStream = createAsyncThunk(
  "liveStream/stopLiveStream",
  async (id: string, { dispatch }) => {
    try {
      await LiveStreamServiceInstance.stopLiveStream(id);
      await dispatch(getLiveStreamById({ id, silent: true }));
      notification.success({
        message:
          "Stopping your live stream! You can update & start it again at any time.",
      });
    } catch (err: any) {
      console.log({ err });
    }
  }
);
