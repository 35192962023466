import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SubscriptionServiceInstance from "../../api/SubscriptionService";
import {
  IInvoice,
  IStreamingAccount,
  ISubscriptionPlan,
} from "../../types/models";
import { message } from "antd";
import {
  IActivateAccountBody,
  IPaypalsubscritpionAccountBody,
} from "../../types/interfaces";

interface initialState {
  streamingAccounts: IStreamingAccount[];
  streamingAccountsLoading: boolean;
  subscriptionPlans: ISubscriptionPlan[];
  subscriptionPlansLoading: boolean;
  error: any;
  createStreamingAccountLoading: boolean;
  activateStreamingAccountLoading: boolean;
  applyCouponLoading: boolean;
  invoicesHistoryLoading: boolean;
  invoicesHistory: IInvoice[];
}

const initialState: initialState = {
  streamingAccounts: [],
  subscriptionPlans: [],
  error: null,
  subscriptionPlansLoading: false,
  streamingAccountsLoading: false,
  createStreamingAccountLoading: false,
  activateStreamingAccountLoading: false,
  applyCouponLoading: false,
  invoicesHistoryLoading: false,
  invoicesHistory: [],
};

// export const restartStreamingAccount = createAsyncThunk(
//   "restartStreamingAccount",
//   async (id) => {
//     const res = await axiosInstance.post(`/streaming-account/${id}/restart`);
//     return await res.data;
//   }
// );

export const SubscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setStreamingAccountsLoading(state, action: PayloadAction<boolean>) {
      state.streamingAccountsLoading = action.payload;
    },
    setCreateStreamingAccountLoading(state, action: PayloadAction<boolean>) {
      state.createStreamingAccountLoading = action.payload;
    },
    setStreamingAccounts(state, action: PayloadAction<IStreamingAccount[]>) {
      state.streamingAccounts = action.payload;
    },
    createNewSubscription(state, action: PayloadAction<IStreamingAccount>) {
      state.streamingAccounts.unshift(action.payload);
    },
    setaAtivateStreamingAccountLoading(state, action: PayloadAction<boolean>) {
      state.activateStreamingAccountLoading = action.payload;
    },
    getStreamingAccountsAfterDeleting(
      state,
      action: PayloadAction<IStreamingAccount>
    ) {
      state.streamingAccounts = state.streamingAccounts.filter(
        (item) => item.id !== action.payload.id
      );
    },

    getSubscriptionPlansLoading(state, action: PayloadAction<boolean>) {
      state.subscriptionPlansLoading = action.payload;
    },
    getSubscriptionPlansReducer(
      state,
      action: PayloadAction<ISubscriptionPlan[]>
    ) {
      state.subscriptionPlans = action.payload;
    },

    applyCouponLoading(state, action: PayloadAction<boolean>) {
      state.applyCouponLoading = action.payload;
    },

    getInvoices(state, action: PayloadAction<IInvoice[]>) {
      state.invoicesHistory = action.payload;
    },
    setInvoicesHistoryLoading(state, action: PayloadAction<boolean>) {
      state.invoicesHistoryLoading = action.payload;
    },
  },
});

export const {
  setStreamingAccountsLoading,
  setStreamingAccounts,
  setCreateStreamingAccountLoading,
  createNewSubscription,
  setaAtivateStreamingAccountLoading,
  getStreamingAccountsAfterDeleting,
  getSubscriptionPlansLoading,
  getSubscriptionPlansReducer,
  applyCouponLoading,
  getInvoices,
  setInvoicesHistoryLoading,
} = SubscriptionSlice.actions;

export const getStreamingAccountsAction = createAsyncThunk(
  "getStreamingAccount",
  async (_, { dispatch }) => {
    dispatch(setStreamingAccountsLoading(true));
    try {
      const res = await SubscriptionServiceInstance.getStreamingAccounts();
      dispatch(setStreamingAccounts(res.data));
    } catch (err: any) {
      message.error(err.message);
    }
    dispatch(setStreamingAccountsLoading(false));
  }
);

export const createStreamingAccount = createAsyncThunk(
  "createStreamingAccount",
  async (data: { name: string }, { dispatch }) => {
    dispatch(setCreateStreamingAccountLoading(true));
    try {
      const res = await SubscriptionServiceInstance.createStreamingAccount(
        data
      );
      dispatch(createNewSubscription(res.data));
    } catch (err: any) {
      message.error(err.message);
    }
    dispatch(setCreateStreamingAccountLoading(false));
  }
);

export const deactivateStreamingAccount = createAsyncThunk(
  "deactivateStreamingAccount",
  async (id: string, { dispatch }) => {
    dispatch(setaAtivateStreamingAccountLoading(true));
    try {
      await SubscriptionServiceInstance.deActivateStreamingAccount(id);
      await dispatch(getStreamingAccountsAction());
      message.success("Deactivated successfully");
    } catch (err: any) {
      message.error(err.message);
    }
    dispatch(setaAtivateStreamingAccountLoading(false));
  }
);

export const reactivateStreamingAccount = createAsyncThunk(
  "reactivateStreamingAccount",
  async (id: string, { dispatch }) => {
    dispatch(setaAtivateStreamingAccountLoading(true));
    try {
      await SubscriptionServiceInstance.reActivateStreamingAccount(id);
      await dispatch(getStreamingAccountsAction());
      message.success("Reactivated successfully");
    } catch (err: any) {
      message.error(err.message);
    }
    dispatch(setaAtivateStreamingAccountLoading(false));
  }
);

export const deleteStreamingAccount = createAsyncThunk(
  "deleteStreamingAccount",
  async (id: string, { dispatch }) => {
    dispatch(setCreateStreamingAccountLoading(true));
    try {
      const res = await SubscriptionServiceInstance.deleteStreamingAccount(id);
      dispatch(getStreamingAccountsAfterDeleting(res.data));
      message.success("Deleted successfully");
    } catch (err: any) {
      message.error(err.message);
    }
    dispatch(setCreateStreamingAccountLoading(false));
  }
);

export const getSubscriptionPlans = createAsyncThunk(
  "getSubscriptionPlans",
  async (_, { dispatch }) => {
    dispatch(getSubscriptionPlansLoading(true));
    try {
      const res = await SubscriptionServiceInstance.getSubscriptionPlans();
      dispatch(getSubscriptionPlansReducer(res.data));
    } catch (err: any) {
      message.error(err.message);
    }
    dispatch(getSubscriptionPlansLoading(false));
  }
);

export const activateStreamingAccountPaypal = createAsyncThunk(
  "activateStreamingAccountPaypal",
  async (data: IPaypalsubscritpionAccountBody, { dispatch }) => {
    message.success(
      "Your subscription is being processed and should be active shortly",
      5
    );
    dispatch(setaAtivateStreamingAccountLoading(true));
    try {
      await SubscriptionServiceInstance.activateStreamingAccountPaypal(data);
      await dispatch(getStreamingAccountsAction());
    } catch (err: any) {
      message.error(err.response?.data?.message || "Something went wrong");
    }
    dispatch(setaAtivateStreamingAccountLoading(false));
  }
);

export const activateStreamingAccount = createAsyncThunk(
  "activateStreamingAccount",
  async (data: IActivateAccountBody, { dispatch }) => {
    dispatch(setaAtivateStreamingAccountLoading(true));
    try {
      await SubscriptionServiceInstance.activateStreamingAccount(data);
      await dispatch(getStreamingAccountsAction());
      message.success("Your subscription is now activated!");
    } catch (err: any) {
      message.error(
        err.response?.data?.message ||
          err?.error?.message ||
          "Something went wrong"
      );
    }
  }
);

export const applyCoupon = createAsyncThunk(
  "applyCoupon",
  async (coupon: string, { dispatch }) => {
    dispatch(applyCouponLoading(true));
    try {
      const res = await SubscriptionServiceInstance.applyCoupon(coupon);
      message.success("Applied Successfully!");
      return res.data;
    } catch (err: any) {
      message.error(err?.error?.message || "Invalid coupon");
    }
    dispatch(applyCouponLoading(false));
  }
);

export const getInvoicesHistory = createAsyncThunk(
  "getInvoicesHistory",
  async (_, { dispatch }) => {
    dispatch(setInvoicesHistoryLoading(true));
    try {
      const res = await SubscriptionServiceInstance.getStreamAccountInvoices();
      dispatch(getInvoices(res.data));
    } catch (err: any) {
      message.error(err.message);
    }
    dispatch(setInvoicesHistoryLoading(false));
  }
);
